@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("~react-big-calendar/lib/css/react-big-calendar.css");
@import url("~react-big-calendar/lib/addons/dragAndDrop/styles.css");

/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
} */

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  /* background: red !important; */
}

html {
  overscroll-behavior: none;
}

body {
  background: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  /* color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}
button {
  outline: none;
}
.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
}

/* .animation-text {
  background: linear-gradient(to right, black 0%, black 50%, grey 50%, grey 100%);
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: textColorAnimation 3s linear forwards;
} */

@keyframes textColorAnimation {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0% 0;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
.fc-timegrid-slot-label {
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  line-height: 18px;
}

.fc-daygrid-day-events .fc-daygrid-event {
  display: inline-block;
  white-space: nowrap;
}

.fc-daygrid-event.fc-daygrid-block-event {
  justify-content: center;
  align-items: center;
  display: flex;
}

.fc .fc-col-header-cell {
  text-align: center;
}

.fc-timegrid-slot {
  height: auto !important;
}

.fc-timegrid-slot-label {
  line-height: 40px;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none;
}

/* Track */
/* This will hide the scrollbar in Firefox */
::-webkit-scrollbar-track {
  background: transparent !important;
  display: none;
  scrollbar-width: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 0px;
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent !important;
  display: none;
}

/* Restore scrollbar for Quill editor */
.scrollBar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollBar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollBar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.scrollBar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
.scrollBar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.hide-scroll {
  scrollbar-width: none;
}

.icon-group-wrapper[data-orientation="horizontal"] .icon-btn:not(:only-child):first-child {
  border-end-end-radius: 0;
  border-start-end-radius: 0;
}

.icon-group-wrapper[data-orientation="horizontal"]
  .icon-btn:not(:only-child):not(:first-child):not(:last-child) {
  border-radius: 0;
  /* border-inline-width: calc(var(--button-border-width) / 2); */
}

.icon-group-wrapper[data-orientation="horizontal"] .icon-btn:not(:only-child):last-child {
  border-end-start-radius: 0;
  border-start-start-radius: 0;
}

.ql-editor {
  padding: 10px 0 !important;
  background-color: transparent !important;
}

.ql-editor.ql-blank::before {
  left: 0 !important;
}

.ql-snow .ql-editor img {
  display: inline-block;
}
.ql-container.ql-snow {
  border: 0px !important;
}

.hide-arrow[type="number"]::-webkit-inner-spin-button,
.hide-arrow[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.k-scrollarea > div {
  display: block !important;
  height: 100%;
}

.msg-virtuoso [data-viewport-type="element"] {
  display: flex;
  flex-direction: column;
}

.msg-virtuoso [data-viewport-type="element"] > div:first-child {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* justify-content: end; */
}

.msg-virtuoso [data-viewport-type="element"] > div:nth-child(2) {
  display: flex;
  /* flex: 1; */
  flex-direction: column;
}

.event-name::placeholder {
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: 0.38px;
  color: rgba(0, 0, 0, 0.5);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.emoji-popover .tippy-content {
  padding: 0;
}

.tippy-box[data-theme~="key"] .tippy-arrow {
  color: rgba(255, 255, 255, 1);
}

.tippy-box[data-theme~="white"] .tippy-arrow {
  color: #e6e7ea;
}
.tippy-arrow {
  color: #e6e7ea;
}
.tippy-box[data-theme~="key"] {
  background-color: white;
}

.adaptive-height {
  height: -webkit-fill-available;
}

.adaptive-width {
  width: -webkit-fill-available;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
}

.ql-container {
  font-size: 1rem !important;
}

.calendar-card .ql-container {
  font-size: 12px !important;
}

/* @import url('~react-big-calendar/lib/css/react-big-calendar.css'); */
/* @import url('~react-big-calendar/lib/addons/dragAndDrop/styles.css'); */

.ql-snow .ql-editor h1 {
  font-size: 2rem !important;
}

.ql-snow .ql-editor h2 {
  font-size: 1.5rem !important;
}

.ql-snow .ql-editor h3 {
  font-size: 1.17rem !important;
}

.ql-editor ol {
  padding-left: 0 !important;
}

.ai_summary {
  h3 {
    font-weight: 500;
  }
  h3::before {
    vertical-align: middle;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    color: #000000;
    font-weight: 700;
    content: url("./star-linear.svg");
  }
  ul {
    list-style: disc;
    margin-block-end: 15px;
    margin-block-start: 15px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    padding-inline-start: 32px;
  }
}

.own-msg-viewer a {
  color: white !important;
  text-decoration: underline;
}
.msg-viewer a {
  text-decoration: underline;
}

.fc .fc-button-primary {
  background-color: white !important;
  border-color: white !important;
  color: rgb(0, 0, 0) !important;
}

.fc .fc-button-primary:focus {
  outline: none !important;
  box-shadow: none !important;
}
.calender-card-des .ql-editor {
  display: -webkit-box;
  -webkit-line-clamp: 3;

  height: 70px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc .fc-button-primary:hover {
  background-color: black !important;
  border-color: black !important;
  color: white !important;
}

.fc .fc-button-primary:disabled {
  background-color: white !important;
  border-color: white !important;
  color: rgb(0, 0, 0) !important;
  font-weight: 600 !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: black !important;
  border-color: black !important;
  color: white !important;
}

@supports (-moz-appearance: none) {
  .manage-event-header {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.day-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
}

.event-dot {
  width: 4px;
  height: 4px;
  background-color: #464646;
  border-radius: 50%;
  margin-top: 28px;
  z-index: 9999;
  position: fixed;
}
.text-gradient {
  background: linear-gradient(90deg, #1580fa, #1580fa, #d563f7, #ff7700);
  -webkit-background-clip: text; /* Safari, Chrome */
  background-clip: text; /* Standard */
  -webkit-text-fill-color: transparent; /* Safari, Chrome */
  color: transparent; /* Fallback for other browsers */
  display: inline-block;
}
